<template>
  <div>
    <v-card class="segmented" flat>
      <v-card-title>
        <v-row>
          <v-col cols="12">
            <h5>
              {{ $t(`feedbacks.processTypes_long.${processType}`) }}
            </h5>
          </v-col>
        </v-row>
      </v-card-title>
      <v-select
        v-model="createdBy"
        :items="roleTranslations"
        item-text="label"
        item-value="value"
        :hint="$t('workflows.creationHint')"
        multiple
        chips
        persistent-hint
      />
      <v-row>
        <v-col cols="2">
          <v-switch
            v-model="hasSelfEvaluation"
            :label="$t('workflows.selfEvaluation')"
          />
        </v-col>
        <v-col v-if="hasSelfEvaluation">
          <localization-text-field
            v-model="selfEvaluationTitle"
            :label="$t('workflows.alternateTitle')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-switch
            v-model="hasSupervisorEvaluation"
            :label="$t('workflows.supervisorEvaluation')"
          />
        </v-col>
        <v-col v-if="hasSupervisorEvaluation">
          <localization-text-field
            v-model="supervisorEvaluationTitle"
            :label="$t('workflows.alternateTitle')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-switch
            v-model="hasConclusion"
            :label="$t('workflows.conclusion')"
          />
        </v-col>
        <v-col v-if="hasConclusion">
          <localization-text-field
            v-model="conclusionTitle"
            :label="$t('workflows.alternateTitle')"
          />
        </v-col>
      </v-row>
      <v-switch v-model="hasDialog" :label="$t('workflows.dialog')" />
      <v-select
        v-if="hasConclusion"
        v-model="conclusionForm"
        :items="availableForms"
        :label="$t('workflows.conclusionForm')"
        item-text="label"
        item-value="value"
      />
      <v-select
        v-if="hasConclusion"
        v-model="conclusionType"
        :items="conclusionTypeTranslations"
        item-text="label"
        item:value="value"
        :label="$t('workflows.conclusionType')"
      />

      <v-switch
        v-model="excludeFromProcessList"
        :label="$t('workflows.hideInProcessList')"
      />

      <v-switch
        v-model="hideFromRecipient"
        :label="$t('workflows.hideFromRecipient')"
      />

      <v-card>
        <workflow-states-table
          v-model="statusList"
          :feedbackTypes="availableFeedbackTypes"
          @statusIdModified="statusIdModified($event)"
        />
      </v-card>
      <v-card>
        <workflow-access-table v-model="processAccess" />
      </v-card>
    </v-card>
    <v-btn elevation="2" large @click="saveWorkflow">{{ $t('save') }}</v-btn>
  </div>
</template>

<script>
import { feedbackEnums } from '@/enums/feedbacks.js';
import workflowStatesTable from '@/components/workflows/workflow-states-table.vue';
import workflowAccessTable from '@/components/workflows/workflow-access-table.vue';
import localizationTextField from '@/components/misc/localization-text-field.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  data() {
    return {
      id: '',
      processType: '',
      createdBy: [],
      hasSelfEvaluation: false,
      hasSupervisorEvaluation: false,
      hasDialog: false,
      hasConclusion: false,
      conclusionType: '',
      conclusionForm: null,
      excludeFromProcessList: false,
      hideFromRecipient: false,
      statusList: [],
      processAccess: [],
      selfEvaluationTitle: [],
      supervisorEvaluationTitle: [],
      conclusionTitle: []
    };
  },

  watch: {
    statusList: {
      handler() {
        this.setStatusList(this.statusList);
      },
      deep: true
    },

    workflowSettings() {
      this.setData();
    }
  },

  mounted() {
    this.setData();
  },

  computed: {
    ...mapGetters({
      workflowSettings: 'workflowSettings',
      roles: 'workflows/getRoles',
      feedbackTypes: 'workflows/getFeedbackTypes',
      conclusionTypes: 'workflows/getConclusionTypes',
      forms: 'forms/getForms'
    }),
    roleTranslations() {
      return this.roles.map((x) => ({
        value: x,
        label: this.$t(`feedbacks.donorRoles.${x}`)
      }));
    },
    conclusionTypeTranslations() {
      return this.conclusionTypes.map((x) => ({
        value: x,
        label: this.$t(`workflows.conclusionTypes.${x}`)
      }));
    },
    availableFeedbackTypes() {
      var types = this.feedbackTypes;
      if (!this.hasSelfEvaluation) {
        types = types.filter(
          (x) => x != feedbackEnums.feedbackTypes.SELF_EVALUATION
        );
      }
      if (!this.hasSupervisorEvaluation) {
        types = types.filter(
          (x) => x != feedbackEnums.feedbackTypes.MANAGER_EVALUATION
        );
      }
      if (!this.hasConclusion) {
        types = types.filter(
          (x) => x != feedbackEnums.feedbackTypes.CONCLUSION
        );
      }
      return types;
    },
    availableForms() {
      var forms = this.forms.filter(
        (x) => !x.isDraft && x.type == this.processType
      );
      var self = this;
      var options = forms.map((x) => ({
        value: x.id,
        label: self.localize(x.name)
      }));
      options.push({
        value: '',
        label: '-'
      });
      return options;
    }
  },

  methods: {
    ...mapActions({
      requestSaveWorkflow: 'workflows/saveWorkflow'
    }),
    ...mapMutations({
      setStatusList: 'workflows/setStatusList'
    }),

    statusIdModified(modification) {
      const oldId = modification.oldId;
      const newId = modification.newId;

      for (const status of this.statusList) {
        for (const transition of status.statusChanges) {
          if (transition.statusResult === oldId) {
            transition.statusResult = newId;
          }
        }
      }

      for (const processAccess of this.processAccess) {
        for (const access of processAccess.feedbackTypeAccess) {
          let requiredStatus = access.requiredStatus;
          if (
            requiredStatus.substr(requiredStatus.indexOf('=') + 1) === oldId
          ) {
            access.requiredStatus = requiredStatus.replace(oldId, newId);
          }
        }
      }
    },

    async saveWorkflow() {
      const workflow = this.buildWorkflowData();
      await this.requestSaveWorkflow(workflow);
    },

    buildWorkflowData() {
      return {
        id: this.id,
        processType: this.processType,
        createdBy: this.createdBy,
        hasSelfEvaluation: this.hasSelfEvaluation,
        hasSupervisorEvaluation: this.hasSupervisorEvaluation,
        hasDialog: this.hasDialog,
        hasConclusion: this.hasConclusion,
        conclusionType: this.conclusionType,
        conclusionForm: this.conclusionForm,
        initialStatus: this.statusList.find((x) => x.isInitialStatus).statusId,
        statusList: this.statusList.map(({ isInitialStatus, ...rest }) => {
          isInitialStatus; // TODO: find a better way to exclude this without having to call it for no reason due to ESLint
          return rest;
        }),
        processAccess: this.processAccess,
        excludeFromProcessList: this.excludeFromProcessList,
        hideFromRecipient: this.hideFromRecipient,
        selfEvaluationTitle: this.selfEvaluationTitle,
        supervisorEvaluationTitle: this.supervisorEvaluationTitle,
        conclusionTitle: this.conclusionTitle
      };
    },

    setData() {
      const data = this.workflowSettings.find(
        (x) => x.id === this.$route.params.id
      );
      if (!data) {
        return;
      }
      this.id = data.id;
      this.processType = data.processType.toString();
      this.createdBy = data.createdBy;
      this.hasSelfEvaluation = data.hasSelfEvaluation;
      this.hasSupervisorEvaluation = data.hasSupervisorEvaluation;
      this.hasDialog = data.hasDialog;
      this.hasConclusion = data.hasConclusion;
      this.conclusionType = data.conclusionType;
      this.conclusionForm = data.conclusionForm;
      this.statusList = data.statusList;
      this.statusList.sort((x, y) => x.statusId - y.statusId);
      this.processAccess = data.processAccess;
      this.excludeFromProcessList = data.excludeFromProcessList;
      this.hideFromRecipient = data.hideFromRecipient;
      this.selfEvaluationTitle = data.selfEvaluationTitle ?? [];
      this.supervisorEvaluationTitle = data.supervisorEvaluationTitle ?? [];
      this.conclusionTitle = data.conclusionTitle ?? [];

      this.statusList.forEach((x) => {
        x.isInitialStatus =
          x.statusId.toString() === data.initialStatus.toString();
        x.statusId = x.statusId.toString();
        x.statusChanges = x.statusChanges ?? [];
        x.statusChanges.forEach((y) => {
          y.feedbackType = y.feedbackType.toString();
          y.statusResult = y.statusResult.toString();
        });
      });

      this.setStatusList(this.statusList);
    }
  },

  components: {
    workflowStatesTable,
    workflowAccessTable,
    localizationTextField
  }
};
</script>
